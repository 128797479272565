<template>
  <div class="import-parameters">
    <DxLoadPanel :position="{ of: '#cmbCompanies' }" :visible="isLoading" shading-color="rgba(0,0,0,0.4)" />

    <div v-for="(item, index) in importParameters" :key="index">
      <div :v-show="item.isVisible" class="row pb-2">
        <div class="col-xl-3 d-flex align-items-center" v-if="item.name == 'CompanyID'">
          <span class="pe-2 flex-grow-1">{{ item.displayName }}:</span>
          <DxSelectBox
            :items="companies"
            v-model="item.value"
            display-expr="name"
            value-expr="companyId"
            id="cmbCompanies"
            class="w-100 px-0"
          >
            <DxValidator>
              <DxRequiredRule :message="'Поле ' + item.displayName + ' обязательное'" />
            </DxValidator>
          </DxSelectBox>
        </div>
        <div class="col-xl-3 d-flex align-items-center" v-else-if="item.name == 'UserID'">
          <span class="pe-2 flex-grow-1">{{ item.displayName }}:</span>
          <DxSelectBox
            v-model="item.value"
            display-expr="name"
            value-expr="id"
            :data-source="usersStore"
            id="cmbUsers"
            class="w-100 px-0"
          >
            <DxValidator v-if="item.isRequired">
              <DxRequiredRule :message="'Поле ' + item.displayName + ' обязательное'" />
            </DxValidator>
          </DxSelectBox>
        </div>
        <div class="col-xl-3 d-flex align-items-center" v-else-if="item.type == 'System.DateTime'">
          <span href="#" class="pe-2 flex-grow-1 text-reset">{{ item.displayName }}:</span>

          <DxDateBox
            v-model="item.value"
            v-if="item.name.includes('Month')"
            displayFormat="monthAndYear"
            :calendarOptions="{ maxZoomLevel: 'year', minZoomLevel: 'century' }"
            dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
          >
            <DxValidator v-if="item.isRequired">
              <DxRequiredRule :message="'Поле ' + item.displayName + ' обязательное'" />
            </DxValidator>
          </DxDateBox>
          <DxDateBox v-model="item.value" dateSerializationFormat="yyyy-MM-ddTHH:mm:ss" v-else>
            <DxValidator v-if="item.isRequired">
              <DxRequiredRule :message="'Поле ' + item.displayName + ' обязательное'" />
            </DxValidator>
          </DxDateBox>
        </div>
        <div class="col-xl-3 d-flex align-items-center" v-else-if="item.type == 'System.Int32'">
          <span href="#" class="pe-2 flex-grow-1 text-reset">{{ item.displayName }}:</span>
          <DxNumberBox v-model="item.value">
            <DxValidator v-if="item.isRequired">
              <DxRequiredRule :message="'Поле ' + item.displayName + ' обязательное'" />
            </DxValidator>
          </DxNumberBox>
        </div>
        <div class="col-xl-3 d-flex align-items-center" v-else-if="item.type == 'System.Boolean'">
          <a href="#" class="pe-2 flex-grow-1 text-reset" @click.prevent="item.value = !item.value"> {{ item.displayName }}:</a>
          <DxCheckBox v-model="item.value" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { usersStore } from "@/helpers/common-store/store";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";

export default {
  components: {
    DxValidator,
    DxRequiredRule,
  },
  props: {
    import: {
      type: Object,
      default: () => {
        return {
          ParseParamters: [],
          ImportParameters: [],
        };
      },
    },
  },
  mounted() {
    this.fillCompanies();
  },

  computed: {
    importParameters: {
      get() {
        if (this.import == null) {
          return [];
        }
        return this.import.importParameters;
      },
    },
  },

  data() {
    return {
      isLoading: false,
      companies: [],
      selectedCompany: null,
      usersStore,
    };
  },

  methods: {
    fillCompanies() {
      this.isLoading = true;
      this.selectedCompany = null;
      axios
        .get(`${process.env.VUE_APP_URL}/api/companies/user-company`)
        .then((response) => {
          if (response.data.length) {
            this.selectedCompany = response.data[0];
          }
          this.companies = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style></style>
