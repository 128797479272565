<template>
  <div>
    <DxLoadPanel :position="{ of: '#gvFiles' }" :visible="isLoading" shading-color="rgba(0,0,0,0.4)" />

    <DxButton id="btnUpload" class="mb-2">
      <i class="dx-icon dx-icon-upload align-middle fs-18 me-2"></i>
      <span class="align-middle">Загрузить файлы</span>
    </DxButton>
    <DxButton class="ms-2 mb-2" v-if="selectedFiles.length > 0" @click="deleteFilesClick">
      <div>
        <i class="dx-icon ri-delete-bin-5-line align-middle fs-18 me-2"></i>
        <span class="align-middle">Удалить файлы</span>
      </div>
    </DxButton>

    <DxFileUploader
      ref="fileUploader"
      dialogTrigger="#btnUpload"
      v-show="false"
      name="file"
      upload-mode="instantly"
      :chunk-size="200000"
      :upload-url="fileUploadUrl"
      :uploadHeaders="headers"
      :multiple="true"
      @upload-started="() => (chunks = [])"
      @files-uploaded="gridInstance.refresh()"
    />

    <DxDataGrid
      id="gvFiles"
      ref="grid"
      v-bind="options"
      dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
      :data-source="dataSource"
      :selected-row-keys="selectedFiles"
      :remote-operations="false"
      :column-auto-width="true"
      :row-alternatin-enabled="true"
      :hover-state-enabled="true"
      row-template="dataRowTemplate"
      @selection-changed="selectionChanged($event)"
    >
      <DxColumn data-field="fileName" caption="Название" cssClass="align-middle" />
      <DxColumn
        v-for="(p, i) in getImportParams()"
        :key="i"
        :caption="p.displayName"
        :width="p.type == 'System.String' ? '150' : '100'"
        cssClass="align-middle"
      />
      <DxColumn caption="Удалить" cssClass="align-middle" width="80" />

      <!-- templates -->
      <template #dataRowTemplate="{ data: rowInfo }">
        <tbody class="dx-row">
          <tr>
            <td class="text-center">
              <DxCheckBox v-model="rowInfo.data.selected" @value-changed="selectRow(rowInfo.data, $event)" />
            </td>
            <td>{{ rowInfo.data.fileName }}</td>
            <td v-for="(p, i) in getFileParams(rowInfo.data.parseParameters)" :key="i" class="text-center">
              <DxCheckBox v-model="p.value" @value-changed="p.value = $event.value" v-if="p.type == 'System.Boolean'" />
              <DxTextBox v-model="p.value" @value-changed="p.value = $event.value" v-else-if="p.type == 'System.String'" />
            </td>
            <td class="text-center">
              <a href="#" class="text-danger" @click.prevent="deleteFileClick(rowInfo.data.fileName)">
                <i class="dx-icon ri-delete-bin-5-line align-middle fs-18"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </template>

      <DxScrolling row-rendering-mode="virtual" />
      <DxPaging :page-size="50" />
      <DxPager
        :visible="true"
        :allowed-page-sizes="[5, 10, 50]"
        :show-page-size-selector="true"
        :show-navigation-buttons="true"
        :show-info="true"
      />
      <DxSelection select-all-mode="allMode" show-check-boxes-mode="checkBoxesMode" mode="multiple" showCheckBoxesMode="always" />
    </DxDataGrid>
  </div>
</template>

<script>
import axios from "axios";
import { confirm } from "devextreme/ui/dialog";
import DxFileUploader from "devextreme-vue/file-uploader";

import createStoreExtend from "@/helpers/grid/store";
import { authHeader } from "@/helpers/authservice/auth-header";
import settings from "@/helpers/grid/settings.js";

const fileUploadUrl = process.env.VUE_APP_URL + "/api/Import/UploadChunk";
const filesDeleteUrl = process.env.VUE_APP_URL + "/api/Import/delete-files";

export default {
  components: { DxFileUploader },
  props: {
    import: {
      type: Object,
      default: () => {
        return {
          parseParameters: [],
          importParameters: [],
        };
      },
    },
  },
  computed: {
    gridInstance: {
      get() {
        return this.$refs.grid.instance;
      },
    },
    parseParameters: {
      get() {
        if (this.import == null || !this.import.parseParameters) {
          return [];
        }
        return this.import.parseParameters;
      },
    },
  },
  watch: {
    import() {
      this.gridInstance.refresh();
    },
  },
  data() {
    return {
      isLoading: false,
      dataSource: createStoreExtend({
        key: "fileName",
        loadUrl: `${process.env.VUE_APP_URL}/api/import/files`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: true };
          ajaxOptions.headers = authHeader();
          this.addParams(ajaxOptions.data);
        },
        onLoaded: (result) => {
          this.filesData = result;
          this.filesData.map((e) => {
            e.selected = false;
          });
        },
      }),
      filesData: [],
      files: [],
      headers: authHeader(),
      fileUploadUrl,
      selectedFiles: [],
      options: settings,
    };
  },
  methods: {
    getFilesParameters() {
      return this.filesData;
    },
    selectionChanged(e) {
      this.gridInstance.beginUpdate();
      this.filesData.map((e) => (e.selected = false));
      e.selectedRowsData.map((e) => (e.selected = true));
      this.gridInstance.endUpdate();
      this.gridInstance.repaint();
    },
    selectRow(data, e) {
      if (e.value) {
        this.gridInstance.selectRows([data.fileName], e.value);
      } else {
        this.gridInstance.deselectRows([data.fileName]);
      }
    },
    getImportParams() {
      var p = this.parseParameters.filter((e) => e.isVisible);
      return p.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    getFileParams(data) {
      return data.filter((r) => r.isVisible).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    addParams(data) {
      if (this.import) data.import = this.import.key;
    },
    refresh() {
      this.gridInstance.refresh();
    },
    deleteFileClick(fileName) {
      console.log(fileName);
    },
    deleteFilesClick() {
      var fileNames = this.gridInstance.getSelectedRowsData().map((el) => el.fileName);
      this.deleteFiles(fileNames);
    },
    deleteFiles(fileNames) {
      let result = confirm(
        "<i class='dx-icon ri-delete-bin-5-line align-middle fs-22 me-2'></i><span class='fs-5 align-middle'>Вы уверены?</span>",
        "Удаление файлов"
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          this.isLoading = true;
          //delete-files
          console.log(fileNames);
          axios
            .post(filesDeleteUrl, fileNames)
            .then(() => this.gridInstance.refresh())
            .catch((error) => console.log(error))
            .finally(() => (this.isLoading = false));
        }
      });
    },
  },
};
</script>

<style>
tbody > tr > td,
tbody > tr > td {
  border: 1px solid #ddd !important;
}

.dx-datagrid-table > tbody:first-child > tr > td {
  border-top: none !important;
}

.dx-datagrid-table > tbody:last-child > tr > td {
  border-bottom: none !important;
}

tbody > tr > td:first-child,
tbody > tr > td:last-child {
  border-left: none !important;
  border-right: none !important;
}

tbody.dx-state-hover > tr > td {
  background-color: #eeeeee;
}
</style>
