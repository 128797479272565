<template>
  <DxDataGrid :show-borders="true" :show-column-lines="true" :show-row-lines="true" :data-source="historyData"
    :remote-operations="true" id="gvHistory" ref="gvHistory">
    <DxColumn data-field="date" data-type="date" caption="Дата" :width="125" sort-order="desc" />
    <DxColumn data-field="actualMonth" data-type="date" caption="Месяц" />
    <DxColumn data-field="importTypeId" caption="Тип импорта" :width="125">
      <DxLookup :data-source="importTypesStore" display-expr="name" value-expr="id" />
    </DxColumn>
    <DxColumn data-field="userId" caption="Пользователь">
      <DxLookup :data-source="usersStore" display-expr="name" value-expr="id" />
    </DxColumn>
    <DxScrolling row-rendering-mode="virtual" />
    <DxPaging :page-size="10" />
    <DxPager :visible="true" :allowed-page-sizes="[5, 10, 50]" :show-page-size-selector="true" :show-info="true"
      :show-navigation-buttons="true" />
  </DxDataGrid>
</template>

<script>
import createStoreExtend from "@/helpers/grid/store";
import { authHeader } from "@/helpers/authservice/auth-header";

const historyData = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/import/history`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
    ajaxOptions.headers = authHeader();
  },
});

export default {
  computed: {
    gridInstance: {
      get() {
        return this.$refs.gvHistory.instance;
      },
    },
  },
  data() {
    return {
      historyData,
      importTypesStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/import-types`,
      }),
      usersStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/users/users`,
      }),
    };
  },
  methods: {
    refresh() {
      this.gridInstance.refresh();
    },
  },
};
</script>

<style>
</style>
