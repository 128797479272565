<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <DxLoadPanel :position="loadingPanelPosition" :visible="isLoading" shading-color="rgba(0,0,0,0.4)" />

    <form @submit="onFormSubmit($event)" id="form">
      <DxValidationGroup :ref="groupRefKey">
        <div class="card" id="importTypes">
          <div class="card-header">
            <div class="row">
              <div class="col-xl-3">
                <DxSelectBox :items="importTypes" v-model="selectedImportType" display-expr="displayName" />
              </div>
            </div>
          </div>
          <div class="card-header border-1 py-2 bg-soft-warning">Параметры</div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <ImportParameters :import="selectedImportType" />
              </div>
            </div>
          </div>
        </div>

        <DxValidationSummary class="pb-2" />

        <div class="row">
          <div class="col">
            <FileExplorer ref="fileExplorer" :import="selectedImportType" />
          </div>
        </div>

        <div class="row pt-2">
          <div class="col-xl">
            <DxButton text="Импортировать" id="button" @click="onSubmit" />
          </div>
        </div>

        <div class="row pt-4">
          <div class="col-12">
            <h5>История импорта</h5>
          </div>
          <div class="col pb-3">
            <ImportHistory ref="importHistory" />
          </div>
        </div>
      </DxValidationGroup>
    </form>

    <DxPopupModal
      ref="dxPopup"
      :visible="resultData.length > 0"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="false"
      :show-title="true"
      title="Ошибки"
    >
      <DxPopupPosition at="center" my="center" />
      <div class="h-100 overflow-auto">
        <DxDataGrid id="gvResult" ref="gvResult" v-bind="options" :data-source="resultData">
          <DxColumn data-field="message" caption="Ошибка" />

          <DxPaging :page-size="10" />
          <DxPager
            :visible="true"
            :allowed-page-sizes="[5, 10, 50]"
            :show-page-size-selector="true"
            :show-navigation-buttons="true"
            :show-info="true"
          />
        </DxDataGrid>
        <span class="text-danger">* Импорт не выполнен. Исправьте ошибки и повторите попытку</span>
      </div>
      <DxPopupToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="closeButtonOptions" />
    </DxPopupModal>
  </Layout>
</template>

<script>
import axios from "axios";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import FileExplorer from "@/components/import/file-explorer";
import ImportHistory from "@/components/import/import-history";
import ImportParameters from "@/components/import/import-parameters";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxValidationSummary from "devextreme-vue/validation-summary";
import notify from "devextreme/ui/notify";
import settings from "@/helpers/grid/settings.js";

export default {
  components: {
    Layout,
    PageHeader,
    FileExplorer,
    ImportHistory,
    ImportParameters,
    DxValidationGroup,
    DxValidationSummary,
  },
  mounted() {
    this.fillImportTypes();
  },
  computed: {
    gridInstance: {
      get() {
        return this.$refs.gvResult.instance;
      },
    },
    importHistory: {
      get() {
        return this.$refs.importHistory;
      },
    },
    fileExplorer: {
      get() {
        return this.$refs.fileExplorer;
      },
    },
    validationGroup: function () {
      return this.$refs[this.groupRefKey].instance;
    },
  },
  data() {
    return {
      groupRefKey: "targetGroup",
      title: "Обмен данными",
      items: [],
      loadingPanelPosition: { of: "#importTypes" },
      isLoading: false,
      resultData: [],
      importTypes: [],
      selectedImportType: { Key: "" },
      options: settings,
      closeButtonOptions: {
        text: "Закрыть",
        onClick: () => {
          this.resultData = [];
        },
      },
    };
  },
  methods: {
    fillImportTypes() {
      this.isLoading = true;
      this.selectedImportType = null;
      this.loadingPanelPosition = { of: "#importTypes" };
      axios
        .get(`${process.env.VUE_APP_URL}/api/import/import-types`)
        .then((response) => {
          if (response.data.length) {
            this.selectedImportType = response.data[0];
          }
          this.importTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    setResult(message, errors, isError) {
      this.result.message = message;
      this.result.errors = errors;
      this.result.show = true;
      this.result.successed = !isError;
    },
    onSubmit() {
      var result = this.validationGroup.validate();
      if (!result.isValid) {
        return;
      }

      this.onFormSubmit();
    },
    onFormSubmit() {
      // e.preventDefault();

      this.loadingPanelPosition = { of: "#form" };
      this.isLoading = true;

      notify(
        {
          message: "Данные импортируются",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );

      var filesParameters = this.fileExplorer.getFilesParameters();

      var importType = this.selectedImportType;
      var obj = {
        key: importType.key,
        FilesParameters: filesParameters,
        ImportParameters: importType.importParameters,
      };

      axios
        .post(`${process.env.VUE_APP_URL}/api/import/start-import`, obj)
        .then(() => {
          this.fileExplorer.refresh();
          this.importHistory.refresh();
        })
        .catch((error) => {
          var response = error.response;
          console.log(response);
          if (response.data.errors) {
            // if exists error list
            this.resultData = response.data.errors.messages.map((e) => {
              return { message: e };
            });
          } else {
            // if single error
            this.resultData.push({ message: response.data.title });
          }
          this.gridInstance.refresh();
          this.importHistory.refresh();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
